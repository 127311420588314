import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Container } from 'components/atoms';
import { HEADER_HEIGHT, SIDE_MENU_WIDTH } from 'util/constants';
import { containerWidth, media } from 'util/responsive';
import ASide from './ASide';
import Header from './Header';

const LayoutContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.grayscale.light};
`;

const ContentContainer = styled.div`
  padding-left: 0;
  padding-top: ${HEADER_HEIGHT}px;
  min-height: 100vh;
  width: 100%;

  ${media['md'](css`
    padding-left: ${SIDE_MENU_WIDTH}px;
  `)};

  @media only screen and (min-width: 1600px) {
    width: 1500px;
  }
`;

const LayoutProcess = ({ children }) => {
  const [openSide, setOpenSide] = useState(false);

  const handleOpenSideBar = event => {
    console.log('hola');
    event.stopPropagation();
    setOpenSide(true);
  }
  console.log(openSide);
  return (
    <LayoutContainer width='100%'>
      <ASide open={openSide} onClose={() => setOpenSide(false)} />
      <Header onOpen={handleOpenSideBar} />
      <ContentContainer>
        <Container
          width='100%'
          padding='0'
          sm={{
            padding: '24px'
          }}
        >
          {children}
        </Container>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default LayoutProcess;