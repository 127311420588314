import React from 'react';
import styled from 'styled-components';
import { Container, Icon, Typography } from '../../atoms';
import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-regular-svg-icons';

const Card = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.highlight.lightest};
`;

const General = ({
  icon,
  quantity,
  description,
}) => (
  <Card>
    <Container
      width='100%'
      direction='row'
      alignItems='center'
      justify='center'
      gap={8}
      mb={8}
    >
      <Icon
        icon={icon}
        color='highlight'
        fontSize={16}
        className='fa-fw'
      />
      <Typography
        color='neutral'
        variant='darkest'
        fontSize={16}
        lineHeight={24}
        fontWeight={700}
      >
        {quantity}
      </Typography>
    </Container>
    <Container
      width='100%'
      height={32}
      alignItems='center'
    >
      <Typography
        color='neutral'
        variant='dark'
        fontSize={14}
        lineHeight={16}
        textAlign='center'
      >
        {description}
      </Typography>
    </Container>
  </Card>
);

const Course = ({
  title,
  data,
}) => (
  <Card>
    <Typography
      color='highlight'
      variant='darkest'
      fontSize={16}
      lineHeight={24}
      fontWeight={700}
      lines={2}
      mb={8}
      title={title}
    >
      {title}
    </Typography>
    <Container width='100%' gap={8}>
      <Container
        width='100%'
        direction='row'
        alignItems='center'
        gap={8}
      >
        <Icon icon={faCircleCheck} color='positive' fontSize={18} className='fa-fw' />
        <Typography
          fontSize={12}
          lineHeight={16}
        >
          {data.good}
        </Typography>
      </Container>
      <Container
        width='100%'
        direction='row'
        alignItems='center'
        gap={8}
      >
        <Icon icon={faCircleXmark} color='warning' variant='dark' fontSize={18} className='fa-fw' />
        <Typography
          fontSize={12}
          lineHeight={16}
        >
          {data.bad}
        </Typography>
      </Container>
      <Container
        width='100%'
        direction='row'
        alignItems='center'
        gap={8}
      >
        <Icon icon={faCircleQuestion} color='info' variant='dark' fontSize={18} className='fa-fw' />
        <Typography
          fontSize={12}
          lineHeight={16}
        >
          {data.not_answered}
        </Typography>
      </Container>
    </Container>
  </Card>
);

export default {
  General,
  Course,
};