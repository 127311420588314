import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Card } from '../../atoms';

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  
  thead {
    tr {
      border-bottom: 1px solid ${props => props.theme.colors.primary.medium};
      
      th {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        text-align: left;
        color: ${props => props.theme.colors.highlight.medium};
        padding: 0 16px 16px;
      }
    }
  }
  
  tbody {
    tr {
      td {
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding: 16px;
      }
    }
  }
`;

const DataTable = ({
  headers,
  data,
  dataIdentifier,
  card,
}) => {
  const TableComponent = () => (
    <TableContainer>
      <Table>
        <thead>
        <tr>
          {headers.map(header => (
            <th key={header.id}>{header.label}</th>
          ))}
        </tr>
        </thead>
        <tbody>
        {data.map((item, index) => (
          <tr key={item[dataIdentifier]}>
            {headers.map(header => (
              <td key={header.id}>
                {header.cell(item, index)}
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </Table>
    </TableContainer>
  );

  if (card) {
    return (
      <Card
        maxWidth='100%'
        bgColor='neutral'
        bgVariant='lightest'
      >
        <TableComponent />
      </Card>
    );
  }

  return <TableComponent />;
};

DataTable.propTypes = {
  card: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    cell: PropTypes.func.isRequired
  }).isRequired),
  data: PropTypes.array.isRequired,
  dataIdentifier: PropTypes.string,
};

DataTable.defaultProps = {
  card: false,
  dataIdentifier: 'uuid',
};

export default DataTable;