import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Container, Typography } from 'components/atoms';
import { Chronometer } from 'components/molecules';
import { HEADER_HEIGHT, processStatus, SIDE_MENU_WIDTH } from 'util/constants';
import { media } from 'util/responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 24px;
  box-shadow: 0 6px 12px 0 rgba(12, 39, 31, 0.20);
  background-color: ${props => props.theme.colors.neutral.lightest};
  z-index: 99;

  ${media['md'](css`
    padding: 0 24px 0 ${SIDE_MENU_WIDTH + 24}px;
  `)}
`;

const Header = ({ onOpen }) => {
  const { examId } = useParams();
  const examStatus = useSelector(state => state.examProcess.status);
  const data = useSelector(state => state.examProcess.examData);
  const grade = useSelector(state => state.examProcess.grade);

  return (
    <HeaderWrapper>
      <Container
        direction='row'
        alignItems='center'
      >
        <Container
          display='block'
          md={{
            display: 'none'
          }}
          onClick={onOpen}
        >
          <FontAwesomeIcon icon={faBars} style={{ fontSize: 24 }} />
        </Container>

        <Container
          display='none'
          alignItems='center'
          direction='row'
          gap={8}
          md={{
            display: 'flex'
          }}
        >
          <Typography
            as='h3'
            fontFamily='Sans'
            color='primary'
            fontSize={24}
            lineHeight={32}
            fontWeight={800}
          >
            {data.institutionData?.institution?.abbreviation}
          </Typography>

          <Typography
            color='highlight'
            variant='light'
            fontSize={12}
            lineHeight={16}
            fontWeight={600}
          >
            {data.examProcess?.year} - {data.examProcess?.phase}
          </Typography>
        </Container>


      </Container>
      {processStatus.STARTED !== examStatus ? (
        <>
          {grade && (
            <Typography
              as='span'
              color='positive'
              fontFamily='Sans'
              fontSize={24}
              fontWeight={700}
              lineHeight={32}
            >
              {grade} ptos.
            </Typography>
          )}
        </>
      ) : (
        <Chronometer />
      )}
    </HeaderWrapper>
  );
};

export default Header;