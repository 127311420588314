import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import useOutsideClick from 'hooks/useOutsideClick';
import Loader from '../Loader';

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const Button = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: ${props => props.disabled ? 'none' : 'default'};
  
  >div.loading-spin {
    position: absolute;
    right: 16px;
  }
  
  svg {
    position: absolute;
    color: ${props => props.theme.colors.primary.medium};
    left: 16px;
    
    &.fa-angle-down {
      right: 16px;
      left: unset;
      color: ${props => props.theme.colors.grayscale.darkest};
    }
  }
`;

const SelectButton = styled.div`
  //height: 48px;
  width: 100%;
  border-radius: 16px;
  background-color: ${props => props.disabled ? '#F8F8F8' : props.theme.colors.white};
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  padding: ${props => props.hasIcon ? '16px 32px 16px 40px' : '16px 32px 16px 16px'};
  border: 1px solid ${props => props.error ? props.theme.colors.warning.dark : (props.isFocused ? props.theme.colors.highlight.medium : props.theme.colors.primary.medium)};
  color: ${props => props.disabled ? '#9C9C9C' : props.hasValue ? props.theme.colors.textBlack : props.theme.colors.neutral.medium}; //TODO: change to theme const
  user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.highlight.medium};
  }
  
  &:disabled {
    cursor: pointer;
    pointer-events: auto;
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  top: calc(100% + 4px);
  background-color: ${props => props.theme.colors.primary.light};
  padding: 8px;
  gap: 4px;
  border-radius: 16px;
  max-height: 198px;
  overflow: auto;
  z-index: 3;
`;

const Option = styled.div`
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  background-color: ${props => props.isFocused ? props.theme.colors.primary.medium : 'transparent'};
  color: ${props => props.isFocused ? props.theme.colors.grayscale.lightest : '#392D2D'}; //TODO: change to theme const
  
  &:hover {
    background-color: ${props => props.theme.colors.primary.medium};
    color: ${props => props.theme.colors.grayscale.lightest};
  }
`;

const Select = ({
  search,
  disabled,
  loading,
  placeholder,
  selected,
  onSelect,
  options,
  error,
  icon,
}) => {
  const selectRef = useRef();
  const inputRef = useRef();
  const [openOptions, setOpenOptions] = useState(false);
  const [focusIndex, setFocusIndex] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (options) {
      setFilteredList(options);
    }
  }, [options]);

  useEffect(() => {
    if (openOptions && search) {
      inputRef.current.focus();
    }
  }, [openOptions]);

  useEffect(() => {
    if (searchText.length >= 2 && openOptions) {
      setFilteredList(options.filter(opt => opt.label.toLowerCase().includes(searchText.toLowerCase())));
    }
  }, [searchText]);

  const handleCloseOptions = () => {
    setOpenOptions(false);
    setFocusIndex(null);
    setSearchText('');
    setFilteredList(options);
  };

  useOutsideClick(selectRef, () => {
    handleCloseOptions()
  });

  const handleToggleOptions = () => {
    setTimeout(() => {
      setOpenOptions(state => !state);
    }, search ? 50 : 0);
    setFocusIndex(null);
  };

  const handleOpenOptions = event => {
    if (loading || disabled) return;

    if (!search) {
      event.preventDefault();
    }

    if (!openOptions) {
      if (event.key === 'Enter' || event.key === ' ' || event.key === 'ArrowDown') {
        setOpenOptions(true);
      }
    } else {
      const isDown = event.key === 'ArrowDown';
      const isUp = event.key === 'ArrowUp';

      if (event.key === 'Escape') {
        handleCloseOptions();
      } else if (event.key === 'Enter' && typeof focusIndex === 'number') {
        onSelect(filteredList[focusIndex]);
        handleCloseOptions();
      } else if (isDown || isUp) {
        if (focusIndex === null) {
          setFocusIndex(isDown ? 0 : filteredList.length - 1)
        } else if (focusIndex === 0 && isUp) {
          setFocusIndex(filteredList.length - 1)
        } else if (focusIndex === filteredList.length - 1 && isDown) {
          setFocusIndex(0);
        } else {
          setFocusIndex(state => isDown ? state + 1 : state - 1)
        }
      }
    }
  };

  const handleSelectOption = option => {
    onSelect(option);
    handleCloseOptions();
  };

  const handleChangeSearch = text => {
    setSearchText(text);
  };

  return (
    <SelectWrapper
      ref={selectRef}
    >
      <Button
        disabled={loading || disabled}
        onClick={handleToggleOptions}
      >
        {icon}
        {(!search || (search && !openOptions)) && (
          <SelectButton
            readOnly
            disabled={disabled || loading}
            tabIndex={(loading || disabled) ? '-1' : '0'}
            role='button'
            onKeyDown={handleOpenOptions}
            hasValue={!!selected}
            isFocused={openOptions}
            hasIcon={!!icon}
            error={error}
          >
            {selected ? selected.label : placeholder}
          </SelectButton>
        )}
        {(search && openOptions) && (
          <SelectButton
            as='input'
            ref={inputRef}
            value={searchText}
            onChange={({ target }) => handleChangeSearch(target.value)}
            tabIndex='0'
            onKeyDown={handleOpenOptions}
            hasValue={!!selected}
            isFocused={openOptions}
            error={error}
          />
        )}
        {loading ? (
          <Loader size={16} className='loading-spin' />
        ) : (
          <FontAwesomeIcon icon={faAngleDown} />
        )}
      </Button>
      {openOptions && (
        <OptionsWrapper>
          {filteredList.map((option, index) => (
            <Option
              key={option.id}
              role='option'
              onClick={() => handleSelectOption(option)}
              isFocused={index === focusIndex}
              onMouseEnter={() => setFocusIndex(null)}
            >
              {option.label}
            </Option>
          ))}
        </OptionsWrapper>
      )}
    </SelectWrapper>
  );
};

Select.propTypes = {
  search: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  selected: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  icon: PropTypes.node,
};

Select.defaultProps = {
  search: false,
  disabled: false,
  loading: false,
  placeholder: '',
  selected: undefined,
  icon: undefined,
};

export default Select;