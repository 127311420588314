import { Container, Typography } from 'components/atoms';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorValues, variantValues } from '../../../util/theme';

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border-radius: 16px;
  background-color: ${props => props.theme.colors[props.color][props.variant]};
`;

const CardDashboard = ({
  bgColor,
  bgVariant,
  textVariant,
  title,
  total,
  quantity,
}) => (
  <CardContainer
    color={bgColor}
    variant={bgVariant}
  >
    <Typography
      as='h3'
      color='grayscale'
      fontFamily='Sora'
      variant={textVariant}
      fontSize={16}
      fontWeight={600}
      lineHeight={24}
      textAlign='center'
      mb={8}
    >
      {title}
    </Typography>
    <Typography
      color='grayscale'
      fontFamily='Sora'
      variant={textVariant}
      fontSize={24}
      fontWeight={700}
      lineHeight={32}
      textAlign='center'
      mb={8}
    >
      {total}
    </Typography>
    <Typography
      color='grayscale'
      fontFamily='Sora'
      variant={textVariant}
      fontSize={12}
      lineHeight={16}
      textAlign='center'
    >
      De {quantity} exámenes completados
    </Typography>
  </CardContainer>
);

CardDashboard.propTypes = {
  bgColor: PropTypes.oneOf(colorValues),
  bgVariant: PropTypes.oneOf(variantValues),
  textVariant: PropTypes.oneOf(variantValues),
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

CardDashboard.defaultProps = {
  bgColor: 'success',
  bgVariant: 'medium',
  textVariant: 'darkest',
};

export default CardDashboard;