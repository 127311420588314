import {
  faRankingStar,
  faUser,
  faSliders,
  faChartSimple,
  faLightbulb,
  faAddressCard,
} from '@fortawesome/free-solid-svg-icons';

export const SIDE_MENU_WIDTH = 250;
export const HEADER_HEIGHT = 60;
export const EXAM_SIDE_MENU_WIDTH = 250;

export const STATUS_CREATED = 1;
export const STATUS_STARTED = 2;
export const STATUS_TERMINATED = 3;
export const STATUS_PROCESSED = 4;
export const STATUS_ERROR = 5;

export const processStatus = {
  CREATED: 'CREATED',
  STARTED: 'STARTED',
  TERMINATED: 'TERMINATED',
  PROCESSED: 'PROCESSED',
  ERROR: 'ERROR',
};

export const statusDisplay = {
  CREATED: 'Pendiente',
  STARTED: 'Iniciado',
  TERMINATED: 'Terminado',
  PROCESSED: 'Terminado',
  ERROR: 'Error',
};

export const phaseDisplay = {
  1: 'I',
  2: 'II',
  '1 - 1': 'I - 1',
  '1 - 2': 'I - 2',
  '2 - 1': 'II - 1',
  '2 - 2': 'II - 2',
};

export const menuOptions = [
  {
    label: 'Nuevo examen',
    icon: faLightbulb,
    route: '/examenes/lista',
  },
  {
    label: 'Dashboard',
    icon: faChartSimple,
    route: '/',
  },
  {
    label: 'Mis exámenes',
    icon: faAddressCard,
    route: '/mis-examenes'
  },
  {
    label: 'Ranking',
    icon: faRankingStar,
    route: '/ranking',
  },
  // {
  //   label: 'Suscripciones',
  //   icon: faCoins,
  //   route: '',
  // },
  // {
  //   label: 'Settings',
  //   icon: faGear,
  //   route: '/opciones',
  // }
];

export const headerOptions = [
  {
    label: 'Perfil',
    icon: faUser,
    route: '/mi-perfil',
  },
  // {
  //   label: 'Configuración',
  //   icon: faSliders,
  //   route: '/configuracion',
  // }
];

export const examYearsOptions = [
  {
    id: '2023',
    label: '2023',
  },
];

export const phaseOptions = [
  {
    id: '1',
    label: 'I',
  },
  {
    id: '2',
    label: 'II',
  },
];

export const alignValues = ['flex-start', 'flex-end', 'center', 'space-between'];
