import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Alternative, Container, Typography } from 'components/atoms';
import { sendAnswer, storageAnswer } from 'store/examProcess';
import { media } from '../../../util/responsive';

const Paragraph = styled.div`
  margin: 8px 0 16px;
  
  p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    color: ${props => props.theme.colors.neutral.dark};
  }

  img {
    max-width: 100%;
  }

  table {
    max-width: 100%;
  }
  
  @media only screen and (width <= 768px) {
    img {
      width: auto !important;
      max-width: 100% !important;
      height: auto !important;
    }
    table {
      width: auto !important;
    }
  }
`;

const Question = ({ onlyRead, data, subject }) => {
  const dispatch = useDispatch();
  const answers = useSelector(state => state.examProcess.answers);
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    if (!onlyRead) {
      const answerObj = answers.find(item => item.answer === data.answers[0].uuid);

      if (answerObj) {
        setAnswer(answerObj.alternative);
      }
    } else {
      setAnswer(data.answers[0].alternative);
    }
  }, [answers]);

  const handleAlternative = id => {
    if (onlyRead) return;

    dispatch(storageAnswer({
      answer: data.answers[0].uuid,
      alternative: id === answer ? null : id,
      alreadyAnswered: !!answer,
      subject,
    }));
    dispatch(sendAnswer({
      answer: data.answers[0].uuid,
      alternative: id === answer ? null : id,
    }));
  };

  return (
    <Container mt={8}>
      <Typography
        color='primary'
        variant='dark'
        fontSize={14}
        lineHeight={18}
        fontWeight={600}
      >
        Pregunta {data.order}
      </Typography>

      <Paragraph dangerouslySetInnerHTML={{ __html: data.question }} />

      {data.alternatives.map(alternative => (
        <Alternative
          key={alternative.uuid}
          onlyRead={onlyRead}
          check={answer === alternative.uuid}
          onCheck={() => handleAlternative(alternative.uuid)}
          data={alternative}
        />
      ))}
    </Container>
  );
};

export default Question;