import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Typography } from 'components/atoms';
import { HEADER_HEIGHT, SIDE_MENU_WIDTH, menuOptions } from 'util/constants';
import Logo from 'assets/images/logo_white.svg';
import Logout from 'assets/images/svgs/logout.svg';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/session';
import { media } from '../../../util/responsive';
import useOutsideClick from '../../../hooks/useOutsideClick';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  width: ${SIDE_MENU_WIDTH}px;
  height: 100vh;
  position: fixed;
  left: ${props => props.open ? '0' : '-100%'};
  background-color: ${props => props.theme.colors.primary.dark};
  z-index: 100;
  transition: 1s all ease-in-out;
  
  ${media['md'](css`
    left: 0;
  `)}
`;

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  
  img {
    height: 100%;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const NavItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  
  a, > div {
    display: flex;
    flex: 1;
    position: relative;
    align-items: center;
    padding: 16px 24px;
    gap: 16px;
    border-radius: 16px;
    text-decoration: none;
    background-color: ${props => props.theme.colors.primary.dark};
    cursor: pointer;
    
    &.active {
      background-color: ${props => props.theme.colors.primary.medium};
    }
  }
  
  svg {
    font-size: 1em;
    path {
      fill: ${props => props.theme.colors.neutral.lightest};
    }
  }
`;

const ASide = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location.pathname]);

  useOutsideClick(ref, () => {
    if (open) {
      onClose();
    }
  });

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Wrapper open={open} ref={ref}>
      <LogoContainer>
        <img src={Logo} alt='Skillup logo' />
      </LogoContainer>

      <Container
        flex={1}
        width='100%'
        justify='space-between'
        style={{
          padding: '32px 16px 32px',
        }}
      >
        <NavContainer>
          {menuOptions.map(option => (
            <NavItem key={option.route}>
              <NavLink to={option.route}>
                <FontAwesomeIcon icon={option.icon} className='fa-fw' />
                <Typography
                  as='span'
                  color='neutral'
                  variant='lightest'
                  fontSize={14}
                  lineHeight={20}
                >
                  {option.label}
                </Typography>
              </NavLink>
            </NavItem>
          ))}
        </NavContainer>

        <Container>
          <NavItem>
            <div onClick={handleLogout}>
              <img src={Logout} alt='Cerrar sesión' />
              <Typography
                as='span'
                color='neutral'
                variant='lightest'
                fontSize={14}
                lineHeight={20}
              >
                Cerrar sesión
              </Typography>
            </div>
          </NavItem>
        </Container>
      </Container>
    </Wrapper>
  );
};

export default ASide;