import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Container, Typography } from 'components/atoms';
import { Question } from 'components/molecules';
import { HEADER_HEIGHT } from 'util/constants';
import { useDispatch } from 'react-redux';
import { setFocusOption } from '../../../store/examProcess';

const StatementText = styled.div`
  margin: 24px 0;
  
  p {
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.colors.neutral.darkest};
  }
  
  img {
    max-width: 100%;
  }
  
  table {
    max-width: 100%;
  }

  @media only screen and (width <= 768px) {
    img {
      width: auto !important;
      max-width: 100% !important;
      height: auto !important;
    }
    table {
      width: auto !important;
    }
  }
`;

const Statement = ({ onlyRead, data, prevSubject }) => {
  const dispatch = useDispatch();
  const ref = useRef();

  const handleViewScroll = () => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();

      if (top > 80 && top < 128) {
        dispatch(setFocusOption(data.course.name));
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      handleViewScroll();
      window.addEventListener('scroll', handleViewScroll)
    }

    return () => window.removeEventListener('scroll', handleViewScroll);
  }, []);

  return (
    <>
      {prevSubject !== data.course.name && (
        <Container
          id={data.course.name}
          ref={ref}
          mb={16}
          mt={16}
          style={{
            scrollMarginTop: HEADER_HEIGHT + 24,
          }}
        >
          <Typography
            color='info'
            variant='darkest'
            fontSize={16}
            lineHeight={24}
            fontWeight={700}
          >
            {data.course.name}
          </Typography>
        </Container>
      )}
      {data.statement && (
        <StatementText dangerouslySetInnerHTML={{ __html: data.statement }} />
      )}
      {data.questions.map(question => (
        <Question
          key={question.uuid}
          onlyRead={onlyRead}
          data={question}
          subject={data.course.name}
        />
      ))}
    </>
  );
};

export default Statement;