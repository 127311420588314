import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Outlet } from 'react-router-dom';
import { Container, Grid } from 'components/atoms';
import { SIDE_MENU_WIDTH, HEADER_HEIGHT } from 'util/constants';
import { containerWidth, media } from 'util/responsive';
import ASide from './ASide';
import Header from './Header';

const LayoutContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.primary.lightest};
`;

const ContentContainer = styled.div`
  padding-left: 0;
  padding-top: ${HEADER_HEIGHT}px;
  min-height: 100vh;
  width: 100%;
  
  ${media['md'](css`
    padding-left: ${SIDE_MENU_WIDTH}px;
  `)};

  @media only screen and (width > 1600px) {
    width: 1500px;
  }
`;

const Layout = () => {
  const [openSide, setOpenSide] = useState(false);

  const handleOpenSideBar = event => {
    event.stopPropagation();
    setOpenSide(true);
  }

  return (
    <LayoutContainer>
      <ASide open={openSide} onClose={() => setOpenSide(false)} />
      <Header onOpen={handleOpenSideBar} />
      <ContentContainer>
        <Container
          width='100%'
          padding='24px'
          md={{
            padding: '24px'
          }}
        >
          <Outlet />
        </Container>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default Layout;