import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-simple-flex-grid';
import { useQuery } from '@tanstack/react-query';
import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightLong, faAward } from '@fortawesome/free-solid-svg-icons';
import { Button, Container, Icon, Typography } from 'components/atoms';
import { Chart, StatsCard } from 'components/molecules';
import { getExam, getStatement } from 'util/request';
import THEME from 'util/theme';

const ExamDetail = () => {
  const navigate = useNavigate();
  const { processId, examId } = useParams();

  const examQuery = useQuery(
    ['myExams', examId],
    () => getExam(examId)
      .then(({ data }) => data),
  );

  const statementQuery = useQuery(
    ['statement', examId],
    () => getStatement({
      student_exam_uuid: examId,
      page_size: 0,
    }).then(({ data }) => data),
  );

  const generalData = useMemo(() => ([
    ['Respuestas', 'Cantidad', { role: 'style' }],
    ['Correctas', examQuery.data?.general_statistics.good || 0, THEME.colors.positive.medium],
    ['Incorrectas', examQuery.data?.general_statistics.bad || 0, THEME.colors.warning.dark],
    ['No respondidas', examQuery.data?.general_statistics.not_answered || 0, THEME.colors.info.dark],
  ]), [examQuery.data]);

  const coursesData = useMemo(() => {
    let coursesArr = ['Cargando', 0, 0, 0];

    if (examQuery.data) {
      coursesArr = Object.keys(examQuery.data.course_statistics).map(course => ([
        course,
        examQuery.data.course_statistics[course].good,
        examQuery.data.course_statistics[course].bad,
        examQuery.data.course_statistics[course].not_answered,
      ]));
    }

    return ([
      ['Cursos', 'Correctas', 'Incorrectas', 'No respondidas'],
      ...coursesArr,
    ]);
  }, [examQuery.data]);

  return (
    <Container width='100%'>
      <Typography
        as='h2'
        fontFamily='Sans'
        color='primary'
        variant='darkest'
        fontSize={24}
        lineHeight={32}
        fontWeight={600}
        mb={32}
      >
        Resultados del examen
      </Typography>

      <Typography
        as='h3'
        color='primary'
        variant='dark'
        fontSize={16}
        lineHeight={24}
        fontWeight={700}
        mb={32}
      >
        Resultados generales
      </Typography>

      <Container width='100%' mb={32}>
        <Row gutter={32}>
          <Col sm={5}>
            <Container width='100%' gap={8}>
              <Row gutter={8}>
                <Col offset={4} span={4}>
                  <StatsCard.General
                    icon={faAward}
                    quantity={examQuery.data?.grade}
                    description='Puntaje total'
                  />
                </Col>
                <Col span={4}>
                  <StatsCard.General
                    icon={faCircleCheck}
                    quantity={examQuery.data?.general_statistics.good}
                    description='Respuestas correctas'
                  />
                </Col>
              </Row>

              <Row gutter={8}>
                <Col offset={4} span={4}>
                  <StatsCard.General
                    icon={faCircleXmark}
                    quantity={examQuery.data?.general_statistics.bad}
                    description='Respuestas incorrectas'
                  />
                </Col>
                <Col span={4}>
                  <StatsCard.General
                    icon={faCircleQuestion}
                    quantity={examQuery.data?.general_statistics.not_answered}
                    description='No respondidas'
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col sm={7}>
            <Chart
              type='ColumnChart'
              height='400px'
              data={generalData}
              options={{
                title: 'Gráfica de resultados',
                titleTextStyle: {
                  color: THEME.colors.primary.dark,
                  fontSize: 12,
                  bold: true,
                },
              }}
            />
          </Col>
        </Row>
      </Container>

      <Typography
        as='h3'
        color='primary'
        variant='dark'
        fontSize={16}
        lineHeight={24}
        fontWeight={700}
        mb={32}
      >
        Resultados por cursos
      </Typography>

      <Container
        width='100%'
        alignItems='center'
        justify='center'
        direction='row'
        gap={24}
        mb={32}
      >
        <Typography>
          Ten en cuenta esto:
        </Typography>
        <Container
          direction='row'
          alignItems='center'
          gap={8}
        >
          <Icon icon={faCircleCheck} color='positive' fontSize={18} />
          <Typography
            color='neutral'
            variant='dark'
            fontSize={14}
            lineHeight={18}
          >
            Respuestas correctas
          </Typography>
        </Container>
        <Container
          direction='row'
          alignItems='center'
          gap={8}
        >
          <Icon icon={faCircleXmark} color='warning' variant='dark' fontSize={18} />
          <Typography
            color='neutral'
            variant='dark'
            fontSize={14}
            lineHeight={18}
          >
            Respuestas incorrectas
          </Typography>
        </Container>
        <Container
          direction='row'
          alignItems='center'
          gap={8}
        >
          <Icon icon={faCircleQuestion} color='info' variant='dark' fontSize={18} />
          <Typography
            color='neutral'
            variant='dark'
            fontSize={14}
            lineHeight={18}
          >
            No respondidas
          </Typography>
        </Container>
      </Container>

      <Container width='100%'>
        <Row gutter={24}>
          {examQuery.data && Object.keys(examQuery.data.course_statistics).map(course => (
            <Col
              key={course}
              sm={6}
              md={3}
              lg={2}
            >
              <Container width='100%' mb={24}>
                <StatsCard.Course
                  title={course}
                  data={{
                    good: examQuery.data.course_statistics[course].good,
                    bad: examQuery.data.course_statistics[course].bad,
                    not_answered: examQuery.data.course_statistics[course].not_answered,
                  }}
                />
              </Container>
            </Col>
          ))}
        </Row>
      </Container>

      <Container width='100%'>
        <Typography
          as='h3'
          color='primary'
          variant='dark'
          fontSize={16}
          lineHeight={24}
          fontWeight={700}
        >
          Gráfica por cursos
        </Typography>

        <Chart
          type='ColumnChart'
          height='600px'
          data={coursesData}
          options={{
            chartArea: {
              left: 50,
              right: 50,
              top: 50,
            },
            colors: [THEME.colors.positive.medium, THEME.colors.warning.dark, THEME.colors.info.dark],
            hAxis: {
              textStyle: {
                color: THEME.colors.neutral.medium,
                fontSize: 14,
              },
            },
          }}
        />
      </Container>

      <Container width='100%' alignItems='flex-end'>
        <Button
          content='Ir al examen corregido'
          onClick={() => navigate(`/mis-examenes/${processId}/${examId}/examen`)}
          iconPosition='right'
          weight={400}
          icon={<Icon icon={faArrowRightLong} />}
        />
      </Container>
    </Container>
  );
};

export default ExamDetail;