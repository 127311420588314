import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorValues, variantValues } from 'util/theme';

const shadowBox = '0px 2px 4px 0px rgba(44, 39, 56, 0.08), 0px 1px 2px 0px rgba(44, 39, 56, 0.08)';
const shadowBoxHover = '0px 12px 24px 0px rgba(44, 39, 56, 0.16), 0px 6px 12px 0px rgba(44, 39, 56, 0.08)';

const border = {
  normal: props => `1px solid ${props.theme.colors[props.color][props.variant]}`,
  border: props => `1px solid ${props.theme.colors[props.color][props.variant]}`,
  borderless: () => 'transparent',
};

const background = {
  normal: props => props.theme.colors[props.color][props.variant],
  border: props => props.theme.colors.white,
  borderless: () => 'transparent',
};

const color = {
  normal: props => props.theme.colors.textLight,
  border: props => props.theme.colors[props.color][props.variant],
  borderless: props => props.theme.colors[props.color][props.variant],
};

const borderHover = {
  normal: props => `1px solid ${props.theme.colors[props.color].dark}`,
  border: props => `1px solid ${props.theme.colors[props.color].dark}`,
  borderless: () => 'transparent',
};

const backgroundHover = {
  normal: props => props.theme.colors[props.color].dark,
  border: props => props.theme.colors.white,
  borderless: () => 'transparent',
};

const colorHover = {
  normal: props => props.theme.colors.textLight,
  border: props => props.theme.colors[props.color].dark,
  borderless: props => props.theme.colors[props.color].dark,
};

const fontSizes = {
  small: 12,
  medium: 14,
  large: 16,
};

const lineHeights = {
  small: 16,
  medium: 18,
  large: 24,
}

const paddingSizes = {
  small: '8px 16px',
  medium: '12px 16px',
  large: '',
};

const CustomButton = styled.button(props => ({
  display: 'inline-flex',
  gap: props.gap,
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  height: 52,
  width: props.width,
  padding: paddingSizes[props.size],
  marginLeft: props.center ? 'auto' : undefined,
  marginRight: props.center ? 'auto' : undefined,
  cursor: 'pointer',
  borderWidth: 1,
  borderStyle: 'solid',
  border: border[props.type](props),
  backgroundColor: background[props.type](props),
  boxShadow: props.type === 'borderless' ? 'none' : shadowBox,
  color: color[props.type](props),
  fontWeight: props.weight,
  fontSize: fontSizes[props.size],
  lineHeight: `${lineHeights[props.size]}px`,
  borderRadius: 16,
  transition: '0.2s all',
  pointerEvents: props.$loading ? 'none' : 'default',
  '&:focus, &:hover': {
    outline: 'none',
  },
  '&:hover': {
    boxShadow: props.type === 'borderless' ? 'none' : shadowBoxHover,
    border: borderHover[props.type](props),
    backgroundColor: backgroundHover[props.type](props),
    color: colorHover[props.type](props),
    'svg': {
      color: colorHover[props.type](props),
    }
  },
  '&:disabled': {
    cursor: 'default',
    border: props.type === 'normal' ? props.theme.colors[props.color].light : props.theme.colors.neutral.light,
    backgroundColor: props.type === 'normal' ? props.theme.colors[props.color].light : props.theme.colors.white,
    color: props.theme.colors.neutral.light,
    '&:hover': {
      boxShadow: 'none',
    }
  },
  'svg': {
    color: color[props.type](props),
    transition: '0.2s all',
  },
  'a': {
    textDecoration: 'none',
    color: 'inherit',
  }
}));

const Loader = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${props => fontSizes[props.size] * 1.5}px;
  height: ${props => fontSizes[props.size] * 1.5}px;;
  
  &:after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: #FFFFFF transparent #FFFFFF transparent;
    animation: ring 1.2s linear infinite;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = ({
  type,
  color,
  variant,
  size,
  content,
  fluid,
  width,
  center,
  disabled,
  loading,
  onClick,
  icon,
  iconPosition,
  gap,
  weight,
}) => (
  <CustomButton
    center={center}
    disabled={disabled}
    type={type}
    color={color}
    variant={variant}
    size={size}
    width={fluid ? '100%' : width}
    onClick={onClick}
    $loading={loading}
    gap={gap}
    weight={weight}
  >
    {loading
      ? (<Loader size={size} />)
      : (
        <>
          {iconPosition === 'left' && icon}
          {content}
          {iconPosition === 'right' && icon}
        </>
    )}
  </CustomButton>
)

Button.propTypes = {
  type: PropTypes.oneOf(['normal', 'border', 'borderless']),
  color: PropTypes.oneOf(colorValues),
  variant: PropTypes.oneOf(variantValues),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  content: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  fluid: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  center: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  gap: PropTypes.number,
  weight: PropTypes.number,
};

Button.defaultProps = {
  type: 'normal',
  color: 'primary',
  variant: 'medium',
  size: 'medium',
  fluid: false,
  width: 'auto',
  center: false,
  disabled: false,
  loading: false,
  icon: undefined,
  iconPosition: 'left',
  gap: 16,
  weight: 400,
};

export default Button;